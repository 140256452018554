/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { INDEXES } from 'src/common/apis/constants'
import ConfirmationDialog from 'src/common/components/dialogs/ConfirmationDialog'
import { InfiniteGrid } from 'src/common/components/grid/InfiniteGrid'
import {
  STATUS,
  Status,
  StatusKey,
  StatusValue,
} from 'src/common/components/statuses'
import { useModal } from 'src/common/hooks/use-modal'
import { infiniteGet } from 'src/common/utils/api'
import { Reference } from 'src/common/components/reference'
import { upperCase } from 'lodash'
import TotalWithDifferentCurrency from 'src/common/components/TotalWithDifferentCurrency'
import { StatusItem } from 'src/common/types/common'
import { InlineFitlerColumn } from 'src/common/components/grid/types'
import {
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import StaticAutocompleteFilter from 'src/common/components/autocomplete/common/autocomplete-filters'
import { useGetCustomers } from 'src/common/apis'
import { MOTOptions } from 'src/modules/quotes/form/formConfigs'
import { formatDate } from 'src/common/utils/date-locale'
import { useGetOfficeDateFormat } from 'src/common/hooks/use-get-office-date-format'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Invoice } from 'src/modules/invoices/types'
import {
  useHideInvoiceMutation,
  useShowInvoiceMutation,
} from 'src/modules/invoices/useRequests'

const InvoicesList = () => {
  const { handleClose: handleCloseConfirmation, open: openConfirmation } =
    useModal()

  const handleDeletion = () => {
    handleCloseConfirmation()
  }

  const navigator = useNavigate()

  const handleEdit = (el: any) => {
    el?.file?.id && navigator(`/files/${el?.file?.id}/invoices/${el?.id}`)
  }

  const { dateFormat } = useGetOfficeDateFormat()

  const [selectedInvoice, setSelectedInvoice] = React.useState<Invoice | null>(
    null,
  )

  const {
    handleClose: handleCloseConfirmationHide,
    handleOpen: handleOpenConfirmationHide,
    open: openConfirmationHide,
  } = useModal()

  const hideInvoiceMutation = useHideInvoiceMutation({
    callback: () => {
      setSelectedInvoice(null)
    },
  })
  const showInvoiceMutation = useShowInvoiceMutation({
    callback: () => {
      setSelectedInvoice(null)
    },
  })

  const handleHide = () => {
    if (selectedInvoice) {
      selectedInvoice?.isPrivate
        ? showInvoiceMutation.mutate(selectedInvoice.id as string)
        : hideInvoiceMutation.mutate(selectedInvoice.id as string)
    }
    handleCloseConfirmationHide()
  }

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row: any): { reference: string; isPrivate: boolean } => ({
          reference: row.reference,
          isPrivate: row.isPrivate,
        }),
        header: 'Invoice no.',
        cell: (info: { getValue: () => any }) => (
          <Reference
            reference={info.getValue()?.reference || '--------'}
            type="quote"
            color={info.getValue()?.isPrivate ? 'default' : 'warning'}
            hideTitle
          />
        ),
        size: 200,
        enableSorting: false,
      },
      {
        accessorKey: 'file.reference',
        header: 'File ',
        cell: (info: { getValue: () => any }) =>
          info?.getValue() && (
            <Reference
              type="file"
              reference={info?.getValue()}
              hideTitle
              size="small"
            />
          ),
        size: 200,
        enableSorting: true,
      },
      {
        header: 'Status',
        cell: (row: any) => (
          <>
            <Status
              key={row.getValue().status}
              status={STATUS[row.getValue().status as StatusKey]}
            />
          </>
        ),
        size: 150,
        enableSorting: false,
        accessorFn: (row: any): { status: string } => ({
          status: row.status,
        }),
      },

      {
        header: 'Customer',
        cell: (info: { getValue: () => any }) => info.getValue(),
        size: 300,
        accessorFn: (row: any) => row.customer.name,
        enableSorting: false,
        queryParamName: 'customer_id',
      },

      {
        accessorKey: 'invoiceDate',
        header: 'Invoice date',
        cell: (info: { getValue: () => any }) =>
          formatDate(info.getValue()?.split('T')[0], dateFormat),
        size: 200,
        enableSorting: false,
      },
      {
        accessorKey: 'dueDate',
        header: 'Due Date',
        cell: (info: { getValue: () => any }) =>
          formatDate(info.getValue()?.split('T')[0], dateFormat),
        size: 200,
        enableSorting: false,
      },
      {
        accessorKey: 'mot',
        header: 'MOT',
        cell: (info: { getValue: () => any }) => upperCase(info.getValue()),
        size: 150,
        enableSorting: false,
      },

      {
        header: 'Total',
        align: 'right',
        cell: (row: any) => {
          const { total, conversionRate } = row.getValue()
          return (
            <TotalWithDifferentCurrency
              total={total}
              invoiceCurrency={row.getValue().currency}
              conversionRate={conversionRate}
            />
          )
        },
        size: 0,
        enableSorting: false,
        accessorFn: (
          row: any,
        ): { total: string; currency: string; conversionRate: number } => ({
          total: row.total,
          currency: row.currency,
          conversionRate: row.conversionRate,
        }),
      },
      {
        accessorKey: 'actions',
        Header: 'Actions',
        size: 150,
        enableSorting: false,
        accessorFn: (row: any) => row,
        cell: (row: any) => (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {!row.getValue().isPrivate ? (
              <Tooltip title="Hide invoice from customer ">
                <IconButton
                  id="sales-invoices-hide"
                  aria-label="Hide"
                  title="Hide this invoice"
                  onClick={() => {
                    setSelectedInvoice(row.getValue())
                    handleOpenConfirmationHide()
                  }}
                  color="warning"
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Show invoice from customer ">
                <IconButton
                  id="sales-invoices-show"
                  aria-label="Show"
                  title="Show this invoice"
                  onClick={() => {
                    setSelectedInvoice(row.getValue())
                    handleOpenConfirmationHide()
                  }}
                  color="success"
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ),
      },
    ],
    [dateFormat],
  )

  const customersQuery = useGetCustomers({ convertToOptions: true })

  const statusArray: StatusItem[] = Object.entries(STATUS).map(
    ([key, value]) => ({
      value: key as StatusValue,
      label: <Status key={key as StatusKey} status={value as StatusValue} />,
    }),
  )

  const leadStatusArray = statusArray.filter((item) =>
    ['finalized', 'draft'].includes(item.value),
  )

  const customInlineFilters: InlineFitlerColumn[] = React.useMemo(
    () => [
      {
        name: 'status',
        component: (props: any) => (
          <TextField
            fullWidth
            label="Status"
            name="status"
            {...props}
            variant="outlined"
            size="small"
            select
          >
            <MenuItem key={'0'} value={''}>
              All
            </MenuItem>
            {leadStatusArray.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ),
      },
      {
        name: 'customer',
        component: (props: any) => (
          <StaticAutocompleteFilter
            label="Customer"
            name="customer_id"
            {...props}
            options={customersQuery?.data || []}
            variant="outlined"
            size="small"
            disableClearable
          />
        ),
      },

      {
        name: 'invoiceDate',
        component: (_props: any) => (
          <Typography fontSize="body2">Invoice Date</Typography>
        ),
      },
      {
        name: 'dueDate',
        component: (_props: any) => (
          <Typography fontSize="body2">Due Date</Typography>
        ),
      },
      {
        name: 'subtotal',
        component: (_props: any) => (
          <Typography
            fontSize="body2"
            sx={{ textAlign: 'right', width: '100%' }}
          >
            Subtotal
          </Typography>
        ),
      },
      {
        name: 'total',
        component: (_props: any) => (
          <Typography
            fontSize="body2"
            sx={{ textAlign: 'right', width: '100%' }}
          >
            Total
          </Typography>
        ),
      },
      {
        name: 'mot',
        component: (props: any) => (
          <TextField
            fullWidth
            label="MoT"
            name="mot"
            {...props}
            variant="outlined"
            size="small"
            select
          >
            <MenuItem key={'0'} value={''}>
              All
            </MenuItem>
            {MOTOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customersQuery, MOTOptions],
  )

  const fetchData = async (
    start: number,
    fetchSize: number,
    sorting?: any,
    filters?: string,
    generalSearch?: string,
    useSearch?: boolean,
  ) => {
    try {
      const result = await infiniteGet(
        INDEXES.INVOICE,
        start ?? 1,
        fetchSize ?? 10,
        sorting[0] || { id: 'invoiceDate', desc: true },
        filters,
        generalSearch,
        useSearch,
      )

      const totalRowCount = result?.meta?.totalItems || result?.meta?.items || 0

      if (result.message) {
        toast.error(result.message)
        return {
          data: [],
          meta: {
            totalRowCount: 0,
            totalFilteredItems: 0,
          },
          totalRowCount: 0,
          totalFilteredItems: 0,
          currentPage: 0,
          totalPages: 0,
        }
      }

      return {
        data: result?.data || [],
        meta: {
          totalRowCount: totalRowCount,
          totalFilteredItems: result?.meta?.totalFilteredItems || 0,
        },
        totalRowCount: totalRowCount,
        totalFilteredItems: result?.meta?.totalFilteredItems || 0,
        currentPage: result?.meta?.page || 0,
        totalPages: result?.meta?.totalPages || 0,
      }
    } catch (e) {
      return {
        data: [],
        totalRowCount: 0,
      }
    }
  }

  return (
    <>
      <InfiniteGrid
        columns={columns}
        queryKey={INDEXES.INVOICE}
        onRowClick={handleEdit}
        fetchData={fetchData}
        // showSerachBox={false}
        filtersColumns={null}
        customInlineFilters={customInlineFilters}
      />
      <ConfirmationDialog
        buttonId="delete-invoice-button"
        open={openConfirmation}
        title="Confirm Delete"
        message={`Are you sure you want to delete this invoice?`}
        onConfirm={handleDeletion}
        onCancel={() => handleCloseConfirmation()}
      />
      <ConfirmationDialog
        open={openConfirmationHide}
        message={`Are you sure you want to ${
          selectedInvoice?.isPrivate ? 'show' : 'hide'
        } this invoice?`}
        onCancel={() => handleCloseConfirmationHide()}
        onConfirm={handleHide}
        buttonId="hideInvoiceList"
        title="Hide invoice"
      />
    </>
  )
}

export default InvoicesList
